import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TargetingType } from '../../api/interfaces/CampaignTargeting'
import { AudienceTargeting, BaseTargeting, InternetTargeting, LocationTargeting, TvTargeting } from '../../api/interfaces/TargetingTemplate'
import { DEFAULT_TARGETING_FIELDS } from '../../utils/TargetingFormDefaults'

export interface DefaultTargetingState {
  targetingPriority: TargetingType
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  tvTargeting: TvTargeting
  billboardTargeting: BaseTargeting
  internetTargeting: InternetTargeting
}

const initialState: DefaultTargetingState = DEFAULT_TARGETING_FIELDS

const defaultTargetingTemplate = createSlice({
  name: 'defaultTargetingTemplate',
  initialState,
  reducers: {
    setDefaultTargetingTemplate(_state: DefaultTargetingState, { payload }: PayloadAction<DefaultTargetingState>) {
      return payload
    },
    setLocationTargeting(state: DefaultTargetingState, { payload }: PayloadAction<LocationTargeting>) {
      state.locationTargeting = payload
    },
    setAudienceTargeting(state: DefaultTargetingState, { payload }: PayloadAction<AudienceTargeting>) {
      state.audienceTargeting = payload
    },
    setTvTargeting(state: DefaultTargetingState, { payload }: PayloadAction<TvTargeting>) {
      state.tvTargeting = payload
    },
    setBillboardTargeting(state: DefaultTargetingState, { payload }: PayloadAction<BaseTargeting>) {
      state.billboardTargeting = payload
    },
    setInternetTargeting(state: DefaultTargetingState, { payload }: PayloadAction<InternetTargeting>) {
      state.internetTargeting = payload
    }
  }
})

export const { setDefaultTargetingTemplate, setLocationTargeting, setAudienceTargeting, setTvTargeting, setBillboardTargeting, setInternetTargeting } = defaultTargetingTemplate.actions

export default defaultTargetingTemplate.reducer
