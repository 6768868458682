import React, { useState } from 'react'

import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { AcSearchField } from 'components/Basic/AcSearchField'
import useDebounce from 'utils/useDebounce'

import { LocationPostalCode } from '../../../api/interfaces'
import { useGetPostalCodesQuery } from '../../../api/rtkQueryApi/platform/targetingApi'

export interface IPostalCodeFinderXandr {
  onOptionClick?: (option: LocationPostalCode) => void
}

export const PostalCodeFinderXandr: React.FC<IPostalCodeFinderXandr> = props => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useDebounce(searchValue, 750)
  const zipQuery = useGetPostalCodesQuery({ code: debounceSearch }, { skip: debounceSearch.length < 3 })

  const onSearchValueChanged = (value: string) => {
    setSearchValue(value)
  }

  return (
    <AcSearchField
      value={searchValue}
      isLoading={zipQuery.isLoading || zipQuery.isFetching}
      searchOptions={
        zipQuery.data?.map(
          zip =>
            ({
              label: zip.name,
              key: `${zip.id}`,
              onClick: () => {
                props.onOptionClick?.(zip)
              }
            } as EuiSelectableOption)
        ) ?? []
      }
      onValueChange={onSearchValueChanged}
    />
  )
}
