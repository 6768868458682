import { FrequencyUnit } from '../api/interfaces'
import { AdPlacementType, CampaignDelivery } from '../api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting } from '../api/interfaces/CampaignTargeting'
import { TargetingTemplate } from '../api/interfaces/TargetingTemplate'
import { DefaultDayParts } from '../components/targeters/DayPartPicker'
import { DefaultTargetingState } from '../features/builders/DefaultTargetingSlice'
import { BuilderCampaignType } from '../workflows/CampaignCreateWorkflow'

export const getCampaignDeliveryFromTargetingTemplate = (targetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): CampaignDelivery => {
  if (campaignType === 'TV') {
    return {
      dayParts: targetingTemplate.tvTargeting.dayParts ?? DefaultDayParts,
      frequency: targetingTemplate.tvTargeting.frequencyCapSettings?.frequency ?? 12,
      frequencyUnit: targetingTemplate.tvTargeting.frequencyCapSettings?.frequency ?? FrequencyUnit.week,
      inventorySettings: { targetApp: false, targetWeb: true },
      deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
      placement: AdPlacementType.Everywhere,
      categories: [],
      specific: []
    }
  } else if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
    return {
      dayParts: targetingTemplate.internetTargeting.dayParts ?? DefaultDayParts,
      frequency: targetingTemplate.internetTargeting.frequencyCapSettings?.frequency ?? 12,
      frequencyUnit: targetingTemplate.internetTargeting.frequencyCapSettings?.frequency ?? FrequencyUnit.week,
      inventorySettings: {
        targetApp: targetingTemplate.internetTargeting.advancedInternetTargeting?.inventorySettings?.targetApp ?? false,
        targetWeb: targetingTemplate.internetTargeting.advancedInternetTargeting?.inventorySettings?.targetWeb ?? true
      },
      deviceSettings: {
        targetPc: targetingTemplate.internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPc ?? true,
        targetPhone: targetingTemplate.internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPhone ?? true,
        targetTablet: targetingTemplate.internetTargeting.advancedInternetTargeting?.deviceSettings?.targetTablet ?? true
      },
      placement: AdPlacementType.Everywhere,
      categories: [],
      specific: []
    }
  } else {
    return {
      dayParts: targetingTemplate.billboardTargeting.dayParts ?? DefaultDayParts,
      frequency: targetingTemplate.billboardTargeting.frequencyCapSettings?.frequency ?? 12,
      frequencyUnit: targetingTemplate.billboardTargeting.frequencyCapSettings?.frequency ?? FrequencyUnit.week,
      inventorySettings: { targetApp: false, targetWeb: true },
      deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
      placement: AdPlacementType.Everywhere,
      categories: [],
      specific: []
    }
  }
}

export const getAudienceTargetingFromTargetingTemplate = (targetingTemplate: DefaultTargetingState, campaignType: BuilderCampaignType): AudienceTargeting => {
  return {
    demographics: {
      ageRanges: targetingTemplate.audienceTargeting.ageRanges,
      genders: targetingTemplate.audienceTargeting.genders,
      householdIncomes: targetingTemplate.audienceTargeting.householdIncomes
    },
    segments: campaignType === 'TV' ? targetingTemplate.tvTargeting.segments ?? [] : campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display ? targetingTemplate.internetTargeting.segments ?? [] : []
  }
}

export const getLocationTargetingFromTargetingTemplate = (targetingTemplate: DefaultTargetingState): LocationTargeting => {
  return {
    targetingType: targetingTemplate.locationTargeting.locationType,
    regions: targetingTemplate.locationTargeting.regions,
    dmas: targetingTemplate.locationTargeting.dmas,
    cities: targetingTemplate.locationTargeting.cities,
    zips: targetingTemplate.locationTargeting.postalCodes,
    addresses: targetingTemplate.locationTargeting.addresses,
    geoCircles: targetingTemplate.locationTargeting.geoCircles,
    geoRectangles: targetingTemplate.locationTargeting.geoRectangles,
    geoPolygons: targetingTemplate.locationTargeting.geoPolygons,
    uploadedAudienceUrl: targetingTemplate.locationTargeting.uploadedAudienceUrl
  }
}
