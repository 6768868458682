import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BuilderCampaignType, CampaignCreateStep, CreativeBuilderStep, CreativeCreateStep, TargetStep } from 'workflows/CampaignCreateWorkflow'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiStepsHorizontal, EuiTitle } from '@elastic/eui'
import { EuiStepsHorizontalProps } from '@elastic/eui/src/components/steps/steps_horizontal'

import { TargetingType } from 'api/interfaces/CampaignTargeting'
import { RootState } from 'app/rootReducer'
import { setCampaignCreateStep, setCreativeBuilderStep, setCreativeCreateStep, setTargetStep } from 'features/builders/CampaignCreateSlice'
import { BuilderLaunchPage } from 'features/builders/createCampaign/BuilderLaunchPage'
import CampaignTypeSelectPage from 'features/builders/createCampaign/CampaignTypeSelectPage'
import { BuilderModeSelectPage } from 'features/builders/createCampaign/creativeBuilder/BuilderModeSelectPage'
import { CreativeBuilderWorkflowManager } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { DeliveryInternetPage } from 'features/builders/createCampaign/deliveryBuilder/DeliveryInternetPage'
import { DeliveryTvPage } from 'features/builders/createCampaign/deliveryBuilder/DeliveryTvPage'
import { TargetAudiencePage } from 'features/builders/createCampaign/targetingBuilder/TargetAudiencePage'
import { TargetLocationPage } from 'features/builders/createCampaign/targetingBuilder/TargetLocationPage'
import { TargetModeSelectPage } from 'features/builders/createCampaign/targetingBuilder/TargetModeSelectPage'
import { TargetRetargetingPage } from 'features/builders/createCampaign/targetingBuilder/TargetRetargetingPage'
import { TargetSmartPage } from 'features/builders/createCampaign/targetingBuilder/TargetSmartPage'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { useCampaignCreateSyncManager } from 'features/builders/createCampaign/useCampaignCreateSyncManager'

import { ImportTargetingPage } from './targetingBuilder/ImportTargetingPage'

export const BuilderWorkflowManager: React.FC = () => {
  const { builderComplete } = useCampaignBuilderActions()
  const dispatch = useDispatch()
  useCampaignCreateSyncManager()
  const { campaignCreateWorkflow: campaignWorkflow } = useSelector((state: RootState) => state)

  const getStepStatus = (step: CampaignCreateStep) => {
    if (campaignWorkflow.campaignCreateStep === step) {
      return 'current'
    }
    if (step === CampaignCreateStep.CreativeCreate) {
      return builderComplete.isCreateComplete ? 'complete' : 'incomplete'
    }

    if (step === CampaignCreateStep.Target) {
      return builderComplete.isTargetComplete ? 'complete' : 'incomplete'
    }

    if (step === CampaignCreateStep.Deliver) {
      if (campaignWorkflow.campaignCreateStep === CampaignCreateStep.Launch) {
        return builderComplete.isDeliveryComplete ? 'complete' : 'incomplete'
      }
    }
    return undefined
  }

  const horizontalSteps: EuiStepsHorizontalProps['steps'] = [
    {
      title: 'Create',
      status: getStepStatus(CampaignCreateStep.CreativeCreate),
      onClick: () => {}
    },
    {
      title: 'Target',
      status: getStepStatus(CampaignCreateStep.Target),
      onClick: () => {}
    },
    {
      title: 'Deliver',
      status: getStepStatus(CampaignCreateStep.Deliver),
      onClick: () => {}
    }
  ]

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiHideFor sizes={['s']}>
          <EuiFlexGroup alignItems={'center'}>
            <EuiFlexItem>
              <EuiStepsHorizontal steps={horizontalSteps} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
        </EuiHideFor>
        {campaignWorkflow.campaignCreateStep === CampaignCreateStep.CreativeCreate && (
          <React.Fragment>
            {campaignWorkflow.createWorkflow.creativeCreateStep == CreativeCreateStep.CampaignTypeSelect && <CampaignTypeSelectPage />}
            {campaignWorkflow.createWorkflow.creativeCreateStep == CreativeCreateStep.CreativeBuilderModeSelect && (
              <BuilderModeSelectPage
                onBack={() => {
                  dispatch(setCreativeCreateStep(CreativeCreateStep.CampaignTypeSelect))
                }}
              />
            )}
            {campaignWorkflow.createWorkflow.creativeCreateStep == CreativeCreateStep.CreativeBuilding && (
              <CreativeBuilderWorkflowManager
                onBack={() => {
                  dispatch(setCreativeBuilderStep(CreativeBuilderStep.BusinessInfo))
                }}
              />
            )}
          </React.Fragment>
        )}
        {campaignWorkflow.campaignCreateStep === CampaignCreateStep.Target && (
          <React.Fragment>
            {campaignWorkflow.targetWorkflow.targetStep === TargetStep.Choose && (
              <TargetModeSelectPage
                onBack={() => {
                  dispatch(setCampaignCreateStep(CampaignCreateStep.CreativeCreate))
                }}
              />
            )}
            {campaignWorkflow.targetWorkflow.targetStep === TargetStep.Define && (
              <React.Fragment>
                {campaignWorkflow.campaignTargeting.targetingType === TargetingType.Location && (
                  <React.Fragment>
                    <TargetLocationPage
                      onBack={() => {
                        if (campaignWorkflow.campaignType === BuilderCampaignType.Native || campaignWorkflow.campaignType === BuilderCampaignType.Display) {
                          dispatch(setTargetStep(TargetStep.Choose))
                        } else {
                          dispatch(setCampaignCreateStep(CampaignCreateStep.CreativeCreate))
                        }
                      }}
                    />
                  </React.Fragment>
                )}
                {(campaignWorkflow.campaignTargeting.targetingType === TargetingType.Audience || campaignWorkflow.campaignTargeting.targetingType === TargetingType.CustomAudience) && (
                  <TargetAudiencePage
                    onBack={() => {
                      dispatch(setTargetStep(TargetStep.Choose))
                    }}
                  />
                )}
                {campaignWorkflow.campaignTargeting.targetingType === TargetingType.Smart && (
                  <TargetSmartPage
                    onBack={() => {
                      dispatch(setTargetStep(TargetStep.Choose))
                    }}
                  />
                )}
                {campaignWorkflow.campaignTargeting.targetingType === TargetingType.Retarget && (
                  <TargetRetargetingPage
                    onBack={() => {
                      dispatch(setTargetStep(TargetStep.Choose))
                    }}
                  />
                )}
              </React.Fragment>
            )}
            {campaignWorkflow.targetWorkflow.targetStep === TargetStep.Import && (
              <ImportTargetingPage
                onBack={() => {
                  dispatch(setTargetStep(TargetStep.Choose))
                }}
              />
            )}
          </React.Fragment>
        )}
        {campaignWorkflow.campaignCreateStep === CampaignCreateStep.Deliver && (
          <React.Fragment>
            {(campaignWorkflow.campaignType === BuilderCampaignType.Native || campaignWorkflow.campaignType === BuilderCampaignType.Display) && (
              <DeliveryInternetPage
                onBack={() => {
                  dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
                }}
              />
            )}
            {campaignWorkflow.campaignType === BuilderCampaignType.TV && (
              <DeliveryTvPage
                onBack={() => {
                  dispatch(setCampaignCreateStep(CampaignCreateStep.Target))
                }}
              />
            )}
          </React.Fragment>
        )}
        {campaignWorkflow.campaignCreateStep === CampaignCreateStep.Launch && (
          <BuilderLaunchPage
            onBack={() => {
              dispatch(setCampaignCreateStep(campaignWorkflow.campaignType === BuilderCampaignType.Billboard ? CampaignCreateStep.Target : CampaignCreateStep.Deliver))
            }}
          />
        )}
      </EuiPanel>
    </React.Fragment>
  )
}
