import { Moment } from 'moment'

import { AcImage } from 'api/interfaces'
import { CampaignTargeting } from 'api/interfaces/CampaignTargeting'

export enum CampaignCreateStep {
  CreativeCreate = 'CreativeCreate',
  Target = 'Target',
  Deliver = 'Deliver',
  Launch = 'Launch'
}

export interface CreativeCreateWorkflow {
  creativeCreateStep: CreativeCreateStep
  creativeBuilderWorkflow: CreativeBuilderWorkflow
}

export enum CreativeCreateStep {
  CampaignTypeSelect = 'CampaignTypeSelect',
  CreativeBuilderModeSelect = 'CreativeBuilderModeSelect',
  CreativeBuilding = 'CreativeBuilding'
}

export interface CreativeBuilderWorkflow {
  creativeBuilderType: CreativeBuilderType
  creativeBuilderStep: CreativeBuilderStep
}

export enum CreativeBuilderType {
  PreMade = 'PreMade',
  AdBuilder = 'AdBuilder',
  Custom = 'Custom',
  Upload = 'Upload'
}

export enum CreativeBuilderStep {
  BusinessInfo = 'BusinessInfo',
  Questionnaire = 'Questionnaire',
  Suggestions = 'Suggestions',
  AdBuilder = 'AdBuilder',
  Upload = 'Upload',
  Custom = 'Custom'
}

export interface TargetWorkflow {
  targetStep: TargetStep
}

export enum TargetStep {
  Choose = 'Choose',
  Define = 'Define',
  Import = 'Import'
}

export interface DeliverWorkflow {
  deliverStep: DeliverStep
}

export enum DeliverStep {
  Delivery = 'Delivery'
}

export interface CampaignCreateWorkflow {
  id: string
  campaignType: BuilderCampaignType
  campaignInfo: CampaignInfo
  creativeInfo: CreativeInfo
  businessInfo: BusinessInfo
  campaignTargeting: CampaignTargeting
  campaignCreateStep: CampaignCreateStep
  createWorkflow: CreativeCreateWorkflow
  targetWorkflow: TargetWorkflow
  deliverWorkflow: DeliverWorkflow
}

export interface BusinessInfo {
  businessTypeId?: string
  cityName?: string
  companyName?: string
  websiteUrl?: string
}

export interface CreativeInfo {
  creativeId?: string
  creativeType: CreativeType
  nativeDetails?: NativeDetails[]
  displayDetails?: DisplayDetails[]
  videoDetails?: VideoDetails[]
  billboardDetails?: BillboardDetails[]
  isCustom?: boolean
}

export interface VideoDetails {
  creativeId: string
  baseVideoId: string
  name: string
  type: string
  quality: string
  kind: string
  thumbnailUrl: string
  previewUrl: string
  sections: string[]
  script: string
  uploadedUrl?: string
}

export interface BillboardDetails {
  creativeId?: string
  uploadedUrl: string
  height: number
  width: number
  fileName: string
  fileType: string
}

export interface NativeDetails {
  creativeId?: string
  headline?: string
  body?: string
  companyName?: string
  buttonText?: string
  clickUrl?: string
  acImage?: AcImage
}

export interface DisplayDetails {
  creativeId?: string
  fileName?: string
  displayImageUrl?: string
  displayWidth?: number
  displayHeight?: number
  clickUrl?: string
}

export enum CreativeType {
  Display = 'Display',
  Video = 'Video',
  Native = 'Native',
  Billboard = 'Billboard',
  Custom = 'Custom'
}

export enum BuilderCampaignType {
  Default = 'Default',
  Native = 'Native',
  Display = 'Display',
  TV = 'TV',
  Billboard = 'Billboard',
  Audio = 'Audio'
}

export interface CampaignInfo {
  id?: string
  name: string
  budget: number
  contactName: string
  phone: string
  email: string
  startDate?: Moment
  endDate?: Moment
  isTotalBudget: boolean
}

export const GetBudgetMinimum = (campaignType: BuilderCampaignType, isTotalBudget: boolean): number => {
  if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
    if (isTotalBudget) {
      return 100
    }
    return 70
  }
  if (campaignType === BuilderCampaignType.Billboard) {
    if (isTotalBudget) {
      return 200
    }
    return 150
  }
  if (campaignType === BuilderCampaignType.TV) {
    if (isTotalBudget) {
      return 200
    }
    return 200
  }
  return 100
}
