import React, { useState } from 'react'

import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { AcSearchField } from 'components/Basic/AcSearchField'
import useDebounce from 'utils/useDebounce'

import { LocationCity } from '../../../api/interfaces'
import { useGetCitiesQuery } from '../../../api/rtkQueryApi/platform/targetingApi'

export interface ICityFinderXandr {
  onOptionClick?: (option: LocationCity) => void
}

export const CityFinderXandr: React.FC<ICityFinderXandr> = props => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useDebounce(searchValue, 750)
  const citiesQuery = useGetCitiesQuery({ name: debounceSearch }, { skip: debounceSearch.length < 3 })

  const onSearchValueChanged = (value: string) => {
    setSearchValue(value)
  }

  return (
    <AcSearchField
      value={searchValue}
      isLoading={citiesQuery.isLoading || citiesQuery.isFetching}
      searchOptions={
        citiesQuery.data?.map(
          city =>
            ({
              label: `${city.name}, ${city.regionCode}`,
              key: `${city.id}`,
              onClick: () => {
                props.onOptionClick?.(city)
              }
            } as EuiSelectableOption)
        ) ?? []
      }
      onValueChange={onSearchValueChanged}
    />
  )
}
