import { useState } from 'react'

import { EuiCheckbox, EuiFlexGrid, EuiFlexItem, EuiFormRow, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../../api/interfaces'
import { BaseTargeting, DeviceSettings, InternetTargeting, InventorySettings, Segment } from '../../../api/interfaces/TargetingTemplate'
import { CampaignDeliveryForm } from './CampaignDeliveryForm'
import { SegmentsForm } from './SegmentsForm'

type InternetTargetingFormProps = {
  internetTargeting: InternetTargeting
  onInternetTargetingChanged: (internetTargeting: InternetTargeting) => void
}

export const InternetTargetingForm = ({ internetTargeting, onInternetTargetingChanged }: InternetTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)

  const onSegmentsChange = (segments: Segment[]) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      segments
    })
  }

  const onCampaignDeliveryChange = (campaignDelivery: BaseTargeting) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      ...campaignDelivery
    })
  }

  const onInventorySettingsChange = (inventorySettings: InventorySettings) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      advancedInternetTargeting: {
        ...internetTargeting.advancedInternetTargeting,
        inventorySettings
      }
    })
  }

  const onDeviceSettingsChange = (deviceSettings: DeviceSettings) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      advancedInternetTargeting: {
        ...internetTargeting.advancedInternetTargeting,
        deviceSettings
      }
    })
  }

  return (
    <>
      <SegmentsForm campaignType={CampaignType.Internet} segments={internetTargeting.segments ?? []} onSegmentsChange={onSegmentsChange} />
      <EuiSpacer />
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size={'s'}>
            <h2> Campaign Delivery Strategy</h2>
          </EuiTitle>
          <EuiSpacer />
          <CampaignDeliveryForm campaignDelivery={internetTargeting} onCampaignDeliveryChanged={onCampaignDeliveryChange} />
          <EuiSpacer />
          <EuiFormRow label={'Inventory and Device Settings'}>
            <EuiFlexGrid columns={2}>
              <EuiFlexItem>
                <EuiCheckbox
                  id={'targetApp'}
                  onChange={e =>
                    onInventorySettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.inventorySettings,
                      targetApp: e.target.checked
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.inventorySettings?.targetApp}
                  label={'Target App'}
                />
                <EuiCheckbox
                  id={'targetWeb'}
                  onChange={() =>
                    onInventorySettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.inventorySettings,
                      targetWeb: !internetTargeting.advancedInternetTargeting?.inventorySettings?.targetWeb
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.inventorySettings?.targetWeb}
                  label={'Target Web'}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={'pc'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetPc: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPc
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPc}
                  label={'Target PC'}
                />
                <EuiCheckbox
                  id={'phone'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetPhone: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPhone
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPhone}
                  label={'Target Phone'}
                />
                <EuiCheckbox
                  id={'tablet'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetTablet: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetTablet
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetTablet}
                  label={'Target Tablet'}
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFormRow>
        </>
      )}
    </>
  )
}
