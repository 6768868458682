import { EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect } from '@elastic/eui'

import { BaseTargeting, DayPartitioning, FrequencyCapUnit } from '../../../api/interfaces/TargetingTemplate'
import { DayPartPicker } from './DayPartPicker'

type CampaignDeliveryFormProps = {
  campaignDelivery: BaseTargeting
  onCampaignDeliveryChanged: (campaignDelivery: BaseTargeting) => void
}

export const CampaignDeliveryForm = ({ campaignDelivery, onCampaignDeliveryChanged }: CampaignDeliveryFormProps) => {
  const options: {
    value: FrequencyCapUnit
    text: string
  }[] = [
    { value: FrequencyCapUnit.Hourly, text: 'Hourly' },
    { value: FrequencyCapUnit.Daily, text: 'Daily' },
    { value: FrequencyCapUnit.Weekly, text: 'Weekly' }
  ]

  const onFrequencyChange = (frequency: number) => {
    onCampaignDeliveryChanged({
      ...campaignDelivery,
      frequencyCapSettings: {
        frequency,
        unit: campaignDelivery.frequencyCapSettings?.unit
      }
    })
  }

  const onFrequencyCapUnitChange = (frequencyCapUnit: FrequencyCapUnit) => {
    onCampaignDeliveryChanged({
      ...campaignDelivery,
      frequencyCapSettings: {
        frequency: campaignDelivery.frequencyCapSettings?.frequency,
        unit: frequencyCapUnit
      }
    })
  }

  const onCpmChange = (cpm: number) => {
    onCampaignDeliveryChanged({
      ...campaignDelivery,
      cpm
    })
  }

  const onDayPartsChange = (dayParts: DayPartitioning) => {
    onCampaignDeliveryChanged({
      ...campaignDelivery,
      dayParts
    })
  }

  return (
    <>
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          <EuiFormRow label={'Delivery'}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiFieldNumber
                  fullWidth
                  value={campaignDelivery.frequencyCapSettings?.frequency}
                  step={1}
                  onChange={e => {
                    onFrequencyChange(Number(e.target.value))
                  }}
                  min={1}
                  append={'views / user'}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSelect options={options} value={campaignDelivery.frequencyCapSettings?.unit} hasNoInitialSelection={!campaignDelivery.frequencyCapSettings?.frequency} onChange={e => onFrequencyCapUnitChange(e.target.value as FrequencyCapUnit)} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Day Partitioning' fullWidth>
            <DayPartPicker onSelectedDayPartsChange={onDayPartsChange} dayParts={campaignDelivery.dayParts} />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
