import React, { useState } from 'react'

import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import useDebounce from 'utils/useDebounce'

import { AddressAutoComplete, GeoFence } from '../../../api/interfaces/CampaignTargeting'
import { useGetAutoCompleteAddressesQuery, useLazyGetGeoFenceQuery } from '../../../api/rtkQueryApi/platform/targetingApi'
import { AcSearchField } from '../../Basic/AcSearchField'

export interface IAddressFinder {
  onOptionClick?: (option: GeoFence) => void
}

export const AddressFinder: React.FC<IAddressFinder> = props => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useDebounce(searchValue, 750)
  const autoCompleteQuery = useGetAutoCompleteAddressesQuery({ query: debounceSearch }, { skip: debounceSearch.length < 3 })
  const [getGeoFence] = useLazyGetGeoFenceQuery()

  const onSearchValueChanged = (value: string) => {
    setSearchValue(value)
  }

  const onClick = (address: AddressAutoComplete) => {
    getGeoFence({ placeId: address.placeId }).then(geoFence => {
      if (geoFence.data) {
        props.onOptionClick?.(geoFence.data)
      }
    })
  }

  return (
    <AcSearchField
      value={searchValue}
      isLoading={autoCompleteQuery.isLoading || autoCompleteQuery.isFetching}
      searchOptions={
        autoCompleteQuery.data?.map(
          address =>
            ({
              label: `${address.description}`,
              key: `${address.placeId}`,
              onClick: () => {
                onClick(address)
              }
            } as EuiSelectableOption)
        ) ?? []
      }
      onValueChange={onSearchValueChanged}
    />
  )
}
