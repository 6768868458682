import React, { useState } from 'react'

import { EuiBadge, EuiButton, EuiConfirmModal, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { TargetingTemplate } from 'api/interfaces/TargetingTemplate'

import { useGetAccountsQuery } from '../../api/rtkQueryApi/platform/accountsApi'
import { useCreateTargetingMutation, useDeleteDefaultTargetingMutation, useGetOrganizationDefaultTargetingQuery, useUpdateTargetingMutation } from '../../api/rtkQueryApi/platform/defaultTargetingApi'
import { TargetingFields, TargetingForm } from '../../components/targeting/TargetingForm/TargetingForm'
import { TargetingTable } from '../../components/targeting/TargetingTable'
import { getCurrentOrganization, getCurrentUser } from '../../services/LocalStoreManagerService'
import { DEFAULT_TARGETING_FIELDS } from '../../utils/TargetingFormDefaults'

export const TargetingPage = () => {
  const targeting = useGetOrganizationDefaultTargetingQuery()
  const accounts = useGetAccountsQuery({ isAgency: false })
  const [createTargetingMutation, createTargetingResult] = useCreateTargetingMutation()
  const [updateTargetingMutation, updateTargetingResult] = useUpdateTargetingMutation()
  const [deleteDefaultTargeting, deleteDefaultTargetingResult] = useDeleteDefaultTargetingMutation()
  const currentOrganization = getCurrentOrganization()
  const currentUser = getCurrentUser()

  const [targetingId, setTargetingId] = useState<string | undefined>()
  const [targetingFields, setTargetingFields] = useState<TargetingFields>(DEFAULT_TARGETING_FIELDS)
  const [showDeleteTargetingModal, setShowDeleteTargetingModal] = useState(false)
  const [showCannotDeleteDefaultTargetingModal, setShowCannotDeleteDefaultTargetingModal] = useState(false)
  const [showTargetingFormFlyout, setShowTargetingFormFlyout] = useState(false)
  const [submitType, setSubmitType] = useState<'create' | 'update'>('create')
  const [saved, setSaved] = useState(true)

  const onTargetingUpdated = (updatedFields: TargetingFields) => {
    setTargetingFields(updatedFields)
    setSaved(false)
  }

  const onEditClick = async (targeting: TargetingTemplate) => {
    setTargetingId(targeting.id)
    setTargetingFields(targeting)
    setShowTargetingFormFlyout(true)
    setSubmitType('update')
  }

  const onDeleteClicked = (targeting: TargetingTemplate) => {
    console.log('hi there')

    if (targeting.id !== currentOrganization?.defaultTargetingId && accounts.data?.filter(account => account.defaultTargetingId === targeting.id).length === 0) {
      setTargetingId(targeting.id)
      setShowDeleteTargetingModal(true)
    } else {
      setShowCannotDeleteDefaultTargetingModal(true)
    }
  }

  const onCreateTargetingClicked = () => {
    setShowTargetingFormFlyout(true)
    setSubmitType('create')
    setTargetingFields(DEFAULT_TARGETING_FIELDS)
  }

  const closeModal = () => {
    setShowTargetingFormFlyout(false)
    setTargetingId(undefined)
    setSaved(true)
  }

  const createTargeting = async () => {
    try {
      await createTargetingMutation({
        template: targetingFields,
        username: currentUser?.email ?? ''
      })
      closeModal()
    } catch (error) {
      console.error('ERROR SAVING TARGETING', error)
    }
  }

  const updateTargeting = async () => {
    if (!targetingId) {
      return
    }
    try {
      if (currentOrganization) {
        await updateTargetingMutation({
          template: {
            id: targetingId,
            organizationId: currentOrganization?.id,
            ...targetingFields
          },
          username: currentUser?.email ?? ''
        })
      } else {
        console.warn('No current organization to update targeting')
      }
      closeModal()
    } catch (error) {
      console.error('ERROR SAVING TARGETING', error)
    }
  }

  const deleteTargeting = async () => {
    try {
      if (targetingId) {
        if (currentOrganization) {
          await deleteDefaultTargeting({ id: targetingId, orgId: currentOrganization.id })
            .then(() => {
              setShowDeleteTargetingModal(false)
              setTargetingId(undefined)
            })
            .catch(error => console.error(error))
        } else {
          console.warn('No current organization to delete targeting')
        }
      } else {
        console.warn('No targeting id')
      }
    } catch (error) {
      console.error('ERROR DELETING TARGETING', error)
    }
  }

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>Targeting</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel>
          <EuiFlexGroup justifyContent={'spaceBetween'}>
            <EuiFlexItem>
              <EuiText>
                <h2>All Targeting Templates</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton iconSide={'left'} iconType={'plus'} onClick={onCreateTargetingClicked} fill>
                Targeting
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <TargetingTable organizationDefaultTargetingId={currentOrganization?.defaultTargetingId} targeting={targeting.data} accounts={accounts.data ?? []} loading={targeting.isFetching || accounts.isFetching} onDeleteClicked={onDeleteClicked} onEditClick={onEditClick} />
        </EuiPanel>
      </EuiPageBody>
      {showTargetingFormFlyout && (
        <EuiFlyout onClose={closeModal} outsideClickCloses={false}>
          <EuiFlyoutHeader>
            <EuiText>
              <h2>Edit Targeting</h2>
              {targetingId && (
                <EuiCopy textToCopy={targetingId}>
                  {copy => (
                    <EuiBadge color='default' onClick={copy} onClickAriaLabel='Copy Organization ID'>
                      {targetingId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              )}
            </EuiText>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>{targetingFields && <TargetingForm onTargetingUpdated={onTargetingUpdated} targeting={targetingFields} />}</EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiSpacer size={'s'} />
            <EuiFlexGroup justifyContent={'flexEnd'}>
              <EuiFlexItem grow={false}>
                <EuiButton type='submit' fill disabled={saved} onClick={submitType === 'create' ? createTargeting : updateTargeting} isLoading={createTargetingResult.isLoading || updateTargetingResult.isLoading}>
                  Save Targeting
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      )}
      {showDeleteTargetingModal && targetingId && (
        <EuiConfirmModal title='Delete Targeting?' onCancel={() => setShowDeleteTargetingModal(false)} onConfirm={deleteTargeting} cancelButtonText='Cancel' confirmButtonText='Delete' buttonColor='danger' isLoading={deleteDefaultTargetingResult.isLoading} defaultFocusedButton='confirm'>
          <p>You will permanently delete this targeting</p>
        </EuiConfirmModal>
      )}
      {showCannotDeleteDefaultTargetingModal && (
        <EuiModal
          onClose={() => {
            setShowCannotDeleteDefaultTargetingModal(false)
          }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Cannot Delete Targeting</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>Please select another targeting as the default for the account or organization before deleting this targeting.</EuiModalBody>
        </EuiModal>
      )}
    </EuiPage>
  )
}
