import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { useLocation } from 'react-router-dom'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiSpacer } from '@elastic/eui'

import { getCookie } from 'api/adcritterApi'
import { useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { useGetCampaignsQuery } from 'api/rtkQueryApi/platform/campaignsApi'
import { usePostTouchDashboardMutation } from 'api/rtkQueryApi/platform/dashboardApi'
import { agencySpecialMatchingOffer } from 'app/appSlice'
import AcLoadingChart from 'components/AcLoadingChart'
import PrivateRoute from 'components/PrivateRoute'
import { LoggingUserInPage } from 'components/authentication/loggingUserInPage'
import { AuthenticatorRoutes } from 'components/authentication/useAuthenticationSwitch'
import AudiencePixelPage from 'features/accounts/AudiencePixelPage'
import CreatePage from 'features/accounts/CreatePage'
import SettingsPage from 'features/accounts/SettingsPage'
import { ShopifyTrackingPage } from 'features/accounts/ShopifyTrackingPage'
import BuildersPage from 'features/builders/BuildersPage'
import BundleInsightsPage from 'features/bundleDashboard/BundleInsightsPage'
import CampaignsPage from 'features/campaigns/CampaignsPage'
import GotoAccountPage from 'features/choose/GotoAccountPage'
import GotoAdPage from 'features/choose/GotoAdPage'
import GotoCampaignPage from 'features/choose/GotoCampaignPage'
import GotoOrganizationPage from 'features/choose/GotoOrganizationPage'
import DashboardPage from 'features/dashboard/DashboardPage'
import InvoicesPage from 'features/invoices/InvoicesPage'
import { AgencyOffer } from 'features/offers/AgencyOffer'
import { AgencyOfferAccepted } from 'features/offers/AgencyOfferAccepted'
import { OfferPanel } from 'features/offers/OfferPanel'
import ForgotPage from 'features/outside/forgot/ForgotPage'
import LoginPage from 'features/outside/login/LoginPage'
import RefreshTokenLoginPage from 'features/outside/login/RefreshTokenLoginPage'
import RestartPage from 'features/outside/restart/RestartPage'
import VistaSignUpPage from 'features/outside/vista/VistaSignUpPage'
import ReportsPage from 'features/reports/ReportsPage'
import ShopifyAuthenticatePage from 'features/shopify/ShopifyAuthenticatePage'
import ShopifyEntryPage from 'features/shopify/ShopifyEntryPage'
import { CampaignAdsViewer } from 'features/viewers/CampaignAdsViewer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { RootState } from './rootReducer'
import { TargetingPage } from '../features/targeting/TargetingPage'

const Routes: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoggedIn, isInSignupProcess, isShopifyUser, currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { screenSize } = useSelector((state: RootState) => state.events)

  useEffect(() => {
    if (search.get('agencySpecialMatchingOffer') === 'true') {
      dispatch(agencySpecialMatchingOffer())
    }
  }, [])

  const { isFetching: isFetchingCampaigns } = useGetCampaignsQuery(
    {
      accountId: currentAccount?.id ?? '',
      isAgency: whiteLabel?.isAgencies() ?? false
    },
    { skip: !currentAccount || !whiteLabel }
  )
  const { isFetching: isFetchingCanSeeBundleInsights, data: canSeeBundleInsights } = useGetCanSeeBundleInsightsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount || !currentOrganization })
  const [postTouchDashboard, postTouchDashboardResult] = usePostTouchDashboardMutation()

  useEffect(() => {
    if (!isLoggedIn || !currentOrganization || !whiteLabel || postTouchDashboardResult.isLoading || postTouchDashboardResult.data !== undefined) {
      return
    }

    postTouchDashboard({
      clck: getCookie('_clck'),
      isAgency: whiteLabel.isAgencies()
    })
  }, [isLoggedIn, currentOrganization, whiteLabel, postTouchDashboard])

  useEffect(() => {
    if (!currentOrganization || !postTouchDashboardResult.data) {
      return
    }

    let clarity = (window as any)['clarity']
    if (typeof clarity == 'function') {
      clarity('upgrade', 'dashboard login date updated')
      clarity('set', 'organization_id', currentOrganization.id)
    }
  }, [currentOrganization, postTouchDashboardResult])

  return (
    <Switch>
      <Route
        exact
        path={['/']}
        render={() => {
          if (!isLoggedIn) {
            return <Redirect to='/login' />
          }

          if (isFetchingCanSeeBundleInsights || isFetchingCampaigns) {
            if (screenSize.sm) {
              return (
                <EuiFlexGroup justifyContent='center' responsive={false}>
                  <EuiFlexItem grow={false}>
                    <EuiSpacer size='l' />
                    <EuiLoadingChart size='xl' />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )
            } else {
              return <AcLoadingChart />
            }
          }

          if (screenSize.sm) {
            return <Redirect to='/dashboard' />
          }

          return canSeeBundleInsights ? <Redirect to='/planInsights' /> : <Redirect to='/dashboard' />
        }}
      />
      <Route exact={true} path='/login'>
        <LoginPage />
      </Route>
      <Route exact={true} path='/token_login'>
        <RefreshTokenLoginPage />
      </Route>
      <Route exact={true} path={`/${AuthenticatorRoutes.Vendasta}`}>
        <LoggingUserInPage />
      </Route>
      <Route exact={true} path='/signup'>
        {whiteLabel?.isVista() && <VistaSignUpPage />}
      </Route>
      <Route exact={true} path='/shopify/signup'>
        <ShopifyEntryPage />
      </Route>
      <Route exact={true} path='/shopify'>
        <ShopifyAuthenticatePage />
      </Route>
      <Route exact={true} path='/forgot'>
        <ForgotPage />
      </Route>
      <Route exact={true} path='/adViewer/:campaignId'>
        <CampaignAdsViewer />
      </Route>

      <PrivateRoute path='/restart'>
        <RestartPage />
      </PrivateRoute>

      {isInSignupProcess && (
        <PrivateRoute path='/build'>
          <BuildersPage />
        </PrivateRoute>
      )}
      {!isInSignupProcess && (
        <React.Fragment>
          <PrivateRoute path='/dashboard'>
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute path='/offers/accepted' exact>
            <OfferPanel>
              <AgencyOfferAccepted />
            </OfferPanel>
          </PrivateRoute>
          <PrivateRoute path='/offers' exact>
            <OfferPanel>
              <AgencyOffer />
            </OfferPanel>
          </PrivateRoute>
          <PrivateRoute path='/planInsights'>
            <BundleInsightsPage />
          </PrivateRoute>

          <PrivateRoute path='/settings'>
            <SettingsPage />
          </PrivateRoute>

          <PrivateRoute path='/audiencePixel'>{isShopifyUser ? <ShopifyTrackingPage /> : <AudiencePixelPage />}</PrivateRoute>

          <PrivateRoute path='/gotoAd/:organizationId/:accountId/:campaignId/:adId'>
            <GotoAdPage />
          </PrivateRoute>
          <PrivateRoute path='/gotoCampaign/:organizationId/:accountId/:campaignId'>
            <GotoCampaignPage />
          </PrivateRoute>
          <PrivateRoute path='/gotoCampaignInsights/:organizationId/:accountId/:campaignId'>
            <GotoCampaignPage isInsights={true} />
          </PrivateRoute>
          <PrivateRoute path='/gotoAccount/:organizationId/:accountId'>
            <GotoAccountPage />
          </PrivateRoute>
          <PrivateRoute path='/gotoOrganization/:organizationId'>
            <GotoOrganizationPage />
          </PrivateRoute>

          <PrivateRoute path='/newAccount'>
            <CreatePage />
          </PrivateRoute>

          <PrivateRoute path='/build'>
            <BuildersPage />
          </PrivateRoute>
          <PrivateRoute path='/targeting'>
            <TargetingPage />
          </PrivateRoute>
          <PrivateRoute path='/campaigns'>
            <CampaignsPage />
          </PrivateRoute>
          <PrivateRoute path='/reports'>
            <ReportsPage />
          </PrivateRoute>

          <PrivateRoute path='/invoices'>
            <InvoicesPage />
          </PrivateRoute>
        </React.Fragment>
      )}
    </Switch>
  )
}

export default Routes
