import React, { useState } from 'react'

import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { AcSearchField } from 'components/Basic/AcSearchField'
import useDebounce from 'utils/useDebounce'

import { LocationRegion } from '../../../api/interfaces'
import { useGetRegionsQuery } from '../../../api/rtkQueryApi/platform/targetingApi'

export interface IRegionFinderXandr {
  onOptionClick?: (option: LocationRegion) => void
}

export const RegionFinderXandr: React.FC<IRegionFinderXandr> = props => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useDebounce(searchValue, 750)
  const regionsQuery = useGetRegionsQuery({ name: debounceSearch })

  const onSearchValueChanged = (value: string) => {
    setSearchValue(value)
  }

  return (
    <AcSearchField
      value={searchValue}
      isLoading={regionsQuery.isLoading || regionsQuery.isFetching}
      searchOptions={
        regionsQuery.data?.map(
          region =>
            ({
              label: region.name,
              key: region.code,
              onClick: () => {
                props.onOptionClick?.(region)
              }
            } as EuiSelectableOption)
        ) ?? []
      }
      onValueChange={onSearchValueChanged}
    />
  )
}
