import { TargetingType } from '../api/interfaces/CampaignTargeting'
import { DayPartitioning, LocationTargetingType } from '../api/interfaces/TargetingTemplate'
import { TargetingFields } from '../components/targeting/TargetingForm/TargetingForm'

export const DEFAULT_DAY_PARTS: DayPartitioning = {
  monday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  tuesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  wednesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  thursday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  friday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  saturday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  sunday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
}

export const DEFAULT_TARGETING_FIELDS: TargetingFields = {
  name: 'New Targeting',
  targetingPriority: TargetingType.Location,
  locationTargeting: {
    locationType: LocationTargetingType.USA,
    regions: [],
    postalCodes: [],
    cities: [],
    dmas: [],
    addresses: [],
    geoFences: [],
    geoPolygons: [],
    geoRectangles: [],
    geoCircles: []
  },
  audienceTargeting: {
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all']
  },
  internetTargeting: {
    segments: [],
    dayParts: DEFAULT_DAY_PARTS
  },
  tvTargeting: {
    segments: [],
    dayParts: DEFAULT_DAY_PARTS
  },
  billboardTargeting: {
    dayParts: DEFAULT_DAY_PARTS
  }
}
