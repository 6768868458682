import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Permission, PermissionType } from '../api/interfaces'
import { useGetTargetingByIdQuery } from '../api/rtkQueryApi/platform/defaultTargetingApi'
import { useGetOrganizationQuery } from '../api/rtkQueryApi/platform/organizationsApi'
import { RootState } from '../app/rootReducer'
import { setDefaultTargetingTemplate } from '../features/builders/DefaultTargetingSlice'
import { OrganizationPermissionsContext } from './OrganizationPermissionsContext'

export const OrganizationPermissionsProvider: FC<PropsWithChildren<any>> = props => {
  const [permissions, setPermissions] = useState<Permission[]>([])
  const dispatch = useDispatch()
  const organizationQuery = useGetOrganizationQuery()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const defaultTargetingId = currentAccount?.defaultTargetingId ?? currentOrganization?.defaultTargetingId

  const defaultTargetingQuery = useGetTargetingByIdQuery(defaultTargetingId, { skip: !defaultTargetingId })

  useEffect(() => {
    setPermissions(organizationQuery.data?.permissions ?? [])
  }, [organizationQuery.data])

  useEffect(() => {
    if (defaultTargetingQuery.data) {
      dispatch(setDefaultTargetingTemplate(defaultTargetingQuery.data))
    }
  }, [defaultTargetingQuery.data])

  const hasPermission = (permissionType: PermissionType) => {
    return permissions.some(permission => permission.permissionType === permissionType)
  }

  return <OrganizationPermissionsContext.Provider value={{ permissions, hasPermission }}>{props.children}</OrganizationPermissionsContext.Provider>
}
