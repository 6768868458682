import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

import { CreateTargetingRequest, TargetingTemplate } from '../../interfaces/TargetingTemplate'

const defaultTargetingTags = platformApi.enhanceEndpoints({ addTagTypes: ['DefaultTargetings', 'DefaultTargeting'] })

export const defaultTargetingApi = defaultTargetingTags.injectEndpoints({
  endpoints: build => {
    return {
      getOrganizationDefaultTargeting: build.query<TargetingTemplate[], void>({
        query: () => ({
          url: `defaultTargeting`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'DefaultTargetings' }]
      }),
      getTargetingById: build.query<TargetingTemplate, string | undefined>({
        query: id => ({
          url: `defaultTargeting/${id}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, id) => [{ type: 'DefaultTargeting', id }]
      }),
      createTargeting: build.mutation<TargetingTemplate, { template: CreateTargetingRequest; username: string }>({
        query: request => ({
          url: `defaultTargeting`,
          method: 'POST',
          data: {
            name: request.template.name,
            accountId: request.template.accountId,
            locationTargeting: request.template.locationTargeting,
            audienceTargeting: request.template.audienceTargeting,
            tvTargeting: request.template.tvTargeting,
            billboardTargeting: request.template.billboardTargeting,
            internetTargeting: request.template.internetTargeting,
            targetingPriority: request.template.targetingPriority,
            username: request.username
          },
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        invalidatesTags: [{ type: 'DefaultTargetings' }]
      }),
      updateTargeting: build.mutation<TargetingTemplate, { template: TargetingTemplate; username: string }>({
        query: request => ({
          url: `defaultTargeting/${request.template.id}`,
          method: 'PUT',
          data: JSON.stringify({
            name: request.template.name,
            accountId: request.template.accountId,
            locationTargeting: request.template.locationTargeting,
            audienceTargeting: request.template.audienceTargeting,
            tvTargeting: request.template.tvTargeting,
            billboardTargeting: request.template.billboardTargeting,
            internetTargeting: request.template.internetTargeting,
            targetingPriority: request.template.targetingPriority,
            username: request.username
          }),

          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        invalidatesTags: (resp, _, request) => [{ type: 'DefaultTargeting', id: request.template.id }, { type: 'DefaultTargetings' }]
      }),
      setOrganizationDefaultTargeting: build.mutation<TargetingTemplate, { targetingTemplateId: string }>({
        query: ({ targetingTemplateId }) => ({
          url: `defaultTargeting/setOrganizationDefault`,
          method: 'POST',
          data: {
            targetingTemplateId: targetingTemplateId
          },
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        invalidatesTags: (resp, _, request) => [{ type: 'DefaultTargetings' }, { type: 'DefaultTargeting', id: request.targetingTemplateId }]
      }),
      setAccountDefaultTargeting: build.mutation<TargetingTemplate, { accountId: string; targetingTemplateId: string }>({
        query: ({ accountId, targetingTemplateId }) => ({
          url: `defaultTargeting/setAccountDefault`,
          method: 'POST',
          data: {
            accountId: accountId,
            targetingTemplateId: targetingTemplateId
          },
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        invalidatesTags: (resp, _, request) => [{ type: 'DefaultTargetings' }, { type: 'DefaultTargeting', id: request.targetingTemplateId }]
      }),
      deleteDefaultTargeting: build.mutation<void, { id: string; orgId: string }>({
        query: ({ id }) => {
          return {
            url: `defaultTargeting/${id}`,
            method: 'DELETE',
            isContext: true,
            isAgency: false
          }
        },
        transformResponse: defaultTransformFn,
        invalidatesTags: (_result, _error, { id, orgId }) => [{ type: 'DefaultTargeting', id }, { type: 'DefaultTargetings' }]
      })
    }
  },
  overrideExisting: false
})

export const { useGetOrganizationDefaultTargetingQuery, useGetTargetingByIdQuery, useCreateTargetingMutation, useUpdateTargetingMutation, useSetOrganizationDefaultTargetingMutation, useSetAccountDefaultTargetingMutation, useDeleteDefaultTargetingMutation, useLazyGetTargetingByIdQuery } = defaultTargetingApi
