import { createContext, useContext } from 'react'

import { Permission, PermissionType } from '../api/interfaces'

export interface IOrganizationPermissionsContextValue {
  permissions: Permission[]
  hasPermission: (permissionType: PermissionType) => boolean
}

export const getDefaultOrganizationPermissionsContextValue: () => IOrganizationPermissionsContextValue = () => {
  return {
    permissions: [],
    hasPermission: () => false
  }
}

export const OrganizationPermissionsContext = createContext(getDefaultOrganizationPermissionsContextValue())

export const useOrganizationPermissions = () => {
  return useContext(OrganizationPermissionsContext)
}
