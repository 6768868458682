import { LocationCity, LocationDma, LocationPostalCode, LocationRegion } from '../../interfaces'
import { AddressAutoComplete, GeoFence } from '../../interfaces/CampaignTargeting'
import { defaultTransformFn, platformApi } from './platformApi'

const targetingTag = platformApi.enhanceEndpoints({ addTagTypes: ['Regions', 'Cities', 'DMAs', 'PostalCodes', 'AddressFill', 'GeoFence'] })

const targetingApi = targetingTag.injectEndpoints({
  endpoints: build => {
    return {
      getRegions: build.query<LocationRegion[], { name?: string }>({
        query: ({ name }) => {
          return {
            url: `targeting/regions?${name ? `name=${name}` : ''}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { name }) => [{ type: 'Regions', id: name }]
      }),
      getCities: build.query<LocationCity[], { name?: string }>({
        query: ({ name }) => {
          return {
            url: `targeting/cities?${name ? `name=${name}` : ''}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { name }) => [{ type: 'Cities', id: name }]
      }),
      getAutoCompleteAddresses: build.query<AddressAutoComplete[], { query?: string }>({
        query: ({ query }) => {
          return {
            url: `targeting/address/autocomplete?query=${query}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { query }) => [{ type: 'AddressFill', id: query }]
      }),
      getGeoFence: build.query<GeoFence, { placeId: string }>({
        query: ({ placeId }) => {
          return {
            url: `targeting/address/${placeId}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { placeId }) => [{ type: 'GeoFence', id: placeId }]
      }),
      getDmas: build.query<LocationDma[], { name?: string }>({
        query: ({ name }) => {
          return {
            url: `targeting/dmas?${name ? `name=${name}` : ''}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { name }) => [{ type: 'DMAs', id: name }]
      }),
      getPostalCodes: build.query<LocationPostalCode[], { code?: string }>({
        query: ({ code }) => {
          return {
            url: `targeting/postalCodes?${code ? `code=${code}` : ''}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { code }) => [{ type: 'PostalCodes', id: code }]
      })
    }
  },
  overrideExisting: false
})

export const { useGetCitiesQuery, useGetDmasQuery, useGetPostalCodesQuery, useLazyGetPostalCodesQuery, useGetRegionsQuery, useGetAutoCompleteAddressesQuery, useLazyGetGeoFenceQuery } = targetingApi
