import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiAvatar, EuiButton, EuiButtonIcon, EuiCollapsibleNav, EuiCollapsibleNavGroup, EuiFlexGroup, EuiFlexItem, EuiHeader, EuiHeaderLink, EuiHeaderSection, EuiHeaderSectionItem, EuiHeaderSectionItemButton, EuiHideFor, EuiHorizontalRule, EuiIcon, EuiLink, EuiLoadingSpinner, EuiPopover, EuiPopoverFooter, EuiPopoverTitle, EuiSelectable, EuiSelectableMessage, EuiShowFor, EuiSpacer, EuiSwitch, EuiText, EuiTitle } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { apiGetAccounts } from 'api/adcritterApi'
import { Account, PermissionType } from 'api/interfaces'
import { useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { useCanAccessAgencyPackagesQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { logout, setSelfServePackagesFlyoutOpen, setShowInternalView, showAccountsFlyout, showSuccessToast, updateAccountsState, updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import AccountsFlyout from 'features/accounts/AccountsFlyout'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import { open } from 'features/organization/organizationSlice'
import { SelfServicePackagesFlyoutPage } from 'features/selfServicePackages/SelfServicePackagesFlyoutPage'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { useOrganizationPermissions } from '../providers/OrganizationPermissionsContext'

export enum ViewContent {
  User = 'user',
  Agency = 'agency'
}

export const HeaderBar: React.FC = () => {
  const dispatch = useDispatch()
  const canAccessAgencyPackages = useCanAccessAgencyPackagesQuery(undefined, { pollingInterval: 300000 })
  const { currentUser, currentOrganization, currentAccount, accounts, onlyUsesPlans, isVendastaUser, isShopifyUser, isStaffUser, showInternalView } = useSelector((state: RootState) => state.app)
  const { campaigns } = useSelector((state: RootState) => state.campaigns)
  const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false)
  const [isSmallAccountSwitcherOpen, setIsSmallAccountSwitcherOpen] = useState(false)
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false)
  const [userName, setUserName] = useState('')
  const [userPicture, setUserPicture] = useState('')
  const [accountName, setAccountName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [accountOptions, setAccountOptions] = useState<EuiSelectableOption[]>([])
  const [isAccountsLoading, setIsAccountsLoading] = useState<boolean>(false)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [showStartBlurb, setShowStartBlurb] = useState(false)
  const [showCreateTvBlurb, setShowCreateTvBlurb] = useState(false)
  const [showCreateInternetBlurb, setShowCreateInternetBlurb] = useState(false)
  const [showCreateBillboardBlurb, setShowCreateBillboardBlurb] = useState(false)
  const [showManageSubscription, setShowManagedSubscription] = useState(true)
  const [showAccounts, setShowAccounts] = useState(true)
  const whiteLabel = useWhiteLabel()
  const { hasPermission } = useOrganizationPermissions()
  const canUseTemplates = hasPermission(PermissionType.UseTemplates)

  const { isFetching: isFetchingCanSeeBundleInsights, data: canSeeBundleInsights } = useGetCanSeeBundleInsightsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount || !currentOrganization })

  useEffect(() => {
    if (currentUser && whiteLabel) {
      setUserName(currentUser.name)
      setUserPicture(currentUser.picture)
      setShowAccounts((currentUser.canAccessAllAccounts || accounts.length > 0) && !whiteLabel.isVista())
      setShowManagedSubscription(currentUser.canAccessAllAccounts)
    }
  }, [currentUser, whiteLabel])

  useEffect(() => {
    if (currentOrganization) {
      setOrganizationName(currentOrganization.name)
    }
  }, [currentOrganization])

  useEffect(() => {
    if (currentAccount) {
      setAccountName(currentAccount.name)
      dispatch(fetchCampaigns(currentAccount.id))
    } else {
      setAccountName('<no account yet>')
    }
  }, [dispatch, currentAccount])

  useEffect(() => {
    if (whiteLabel?.hideBlueNav()) {
      setShowStartBlurb(false)
      setShowCreateBillboardBlurb(false)
      setShowCreateInternetBlurb(false)
      setShowCreateTvBlurb(false)

      return
    }

    if (!campaigns || campaigns.length === 0 || (currentAccount && currentAccount.id === 'fccad035-e99a-4c08-864b-d67985e9d9d3')) {
      setShowStartBlurb(true)
      setShowCreateBillboardBlurb(false)
      setShowCreateInternetBlurb(false)
      setShowCreateTvBlurb(false)
    } else {
      let internet = campaigns.filter(c => c.type === 'Internet').length
      let tv = campaigns.filter(c => c.type === 'TV').length
      let billboard = campaigns.filter(c => c.type === 'Billboard').length

      if ((internet > 0 || billboard > 0) && tv === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(true)
      } else if ((tv > 0 || billboard > 0) && internet === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(true)
        setShowCreateTvBlurb(false)
      } else if ((tv > 0 || internet > 0) && billboard === 0) {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(true)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(false)
      } else {
        setShowStartBlurb(false)
        setShowCreateBillboardBlurb(false)
        setShowCreateInternetBlurb(false)
        setShowCreateTvBlurb(false)
      }
    }
  }, [dispatch, campaigns, whiteLabel, currentAccount])

  const onUserProfileClick = () => {
    setIsUserProfileOpen(!isUserProfileOpen)
  }

  const closeUserProfile = () => {
    setIsUserProfileOpen(false)
  }

  const onAccountSwitcherClick = () => {
    setIsAccountSwitcherOpen(!isAccountSwitcherOpen)
    if (!accounts || accounts.length === 0) {
      getAccounts()
    }
  }

  const onSmallAccountSwitcherClick = () => {
    setIsSmallAccountSwitcherOpen(!isAccountSwitcherOpen)
    if (!accounts || accounts.length === 0) {
      getAccounts()
    }
  }

  const closeAccountSwitcher = () => {
    setIsAccountSwitcherOpen(false)
  }

  const closeSmallAccountSwitcher = () => {
    setIsSmallAccountSwitcherOpen(false)
  }

  const getAccounts = () => {
    setIsAccountsLoading(true)
    apiGetAccounts().then(response => {
      dispatch(updateAccountsState(response.data))
      setIsAccountsLoading(false)
    })
  }

  useEffect(() => {
    const onSelectAccount = (account: Account) => {
      dispatch(updateAccountState(account))
      dispatch(showSuccessToast(`Switch account to ${account.name}`))
      closeAccountSwitcher()
      closeSmallAccountSwitcher()
      history.push('/dashboard')
    }

    setAccountOptions(
      accounts.map(a => ({
        label: a.name,
        key: a.id,
        onClick: () => onSelectAccount(a),
        checked: undefined,
        'aria-label': a.name,
        prepend: <EuiIcon type='package' size='m' />
      }))
    )
  }, [accounts, dispatch])

  const onLogoutClick = () => {
    closeAccountSwitcher()
    closeSmallAccountSwitcher()
    dispatch(logout())
    history.push('/login')
  }

  const userProfileButton = (
    <EuiHeaderSectionItemButton aria-controls='userProfile' aria-expanded={isUserProfileOpen} aria-haspopup='true' onClick={onUserProfileClick}>
      <EuiAvatar id='userAvatar' name={userName} size='s' imageUrl={userPicture} />
    </EuiHeaderSectionItemButton>
  )

  const accountSwitcherButton = (
    <EuiHeaderLink id='accountSwitcher' aria-controls='accountSelectorList' aria-expanded={isAccountSwitcherOpen} aria-haspopup='true' aria-label='Choose account' onClick={onAccountSwitcherClick}>
      {accountName}
    </EuiHeaderLink>
  )

  const smallAccountSwitcherButton = (
    <EuiHeaderLink id='smallAccountSwitcher' aria-controls='smallAccountSelectorList' aria-expanded={isSmallAccountSwitcherOpen} aria-haspopup='true' aria-label='Choose account' onClick={onSmallAccountSwitcherClick}>
      {accountName}
    </EuiHeaderLink>
  )

  const onPackagesClick = () => {
    dispatch(setSelfServePackagesFlyoutOpen(true))
  }

  const onAdminClick = () => {
    setIsUserProfileOpen(!isUserProfileOpen)
    closeAccountSwitcher()
    closeSmallAccountSwitcher()
    dispatch(open())
  }

  return (
    currentUser && (
      <React.Fragment>
        <EuiHeader position='fixed' className='euiHeader--platform'>
          <EuiHeaderSection grow={false}>
            <EuiCollapsibleNav
              aria-label='Main navigation'
              isOpen={navIsOpen}
              isDocked={true}
              size={224}
              button={
                <EuiHeaderSectionItemButton id='menuToggle' aria-label='Toggle main navigation' onClick={() => setNavIsOpen(!navIsOpen)} style={{ marginTop: 4 }}>
                  <EuiIcon type='menu' size='m' aria-hidden='true' color='#ffffff' />
                </EuiHeaderSectionItemButton>
              }
              onClose={() => setNavIsOpen(false)}>
              {!isFetchingCanSeeBundleInsights && canSeeBundleInsights && (
                <React.Fragment>
                  <div className='euiCollapsibleNavGroup__heading'>
                    <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                      <EuiFlexItem grow={false}>
                        <EuiIcon type='lensApp' size='l' />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiTitle size='xxs'>
                          <EuiLink onClick={() => history.push('/planInsights')} style={{ color: '#1a1c21' }}>
                            <h3 className='euiCollapsibleNavGroup__title' id='dashboardNavItem'>
                              Insights
                            </h3>
                          </EuiLink>
                        </EuiTitle>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                  <EuiHorizontalRule margin='none' />
                </React.Fragment>
              )}
              {!isFetchingCanSeeBundleInsights && !canSeeBundleInsights && (
                <div className='euiCollapsibleNavGroup__heading'>
                  <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                    <EuiFlexItem grow={false}>
                      <EuiIcon type='dashboardApp' size='l' />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiTitle size='xxs'>
                        <EuiLink onClick={() => history.push('/dashboard')} style={{ color: '#1a1c21' }}>
                          <h3 className='euiCollapsibleNavGroup__title' id='dashboardNavItem'>
                            Dashboard
                          </h3>
                        </EuiLink>
                      </EuiTitle>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </div>
              )}
              {!isVendastaUser && showStartBlurb && (
                <EuiCollapsibleNavGroup background='dark' iconType='globe' title='Start Here' isCollapsible={true} initialIsOpen={true} arrowDisplay='none'>
                  <React.Fragment>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }}>
                      <p>The best way to explore {whiteLabel?.getName()} is to start setting up your first campaign, It's incredibly easy.</p>
                    </EuiText>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }} textAlign='right'>
                      <EuiLink
                        onClick={() => {
                          dispatch(initializeCampaignCreate())
                          CreateCampaignStorageManager.DeleteFromLocalStorage()
                          history.push('/build')
                        }}
                        style={{ color: '#f49342' }}>
                        Create Campaign
                      </EuiLink>
                    </EuiText>
                  </React.Fragment>
                </EuiCollapsibleNavGroup>
              )}
              {showCreateInternetBlurb && (
                <EuiCollapsibleNavGroup background='dark' iconType='globe' title='Internet Ads' isCollapsible={true} initialIsOpen={true} arrowDisplay='none'>
                  <React.Fragment>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }}>
                      <p>Every TV Campaign should be supported with targeted internet ads. They serve as inexpensive reminders.</p>
                    </EuiText>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }} textAlign='right'>
                      <EuiLink
                        onClick={() => {
                          dispatch(initializeCampaignCreate())
                          CreateCampaignStorageManager.DeleteFromLocalStorage()
                          history.push('/build')
                        }}
                        style={{ color: '#f49342' }}>
                        Create Campaign
                      </EuiLink>
                    </EuiText>
                  </React.Fragment>
                </EuiCollapsibleNavGroup>
              )}
              {showCreateTvBlurb && (
                <EuiCollapsibleNavGroup background='dark' iconType='playFilled' title='TV Commercials' isCollapsible={true} initialIsOpen={true} arrowDisplay='none'>
                  <React.Fragment>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }}>
                      <p>Thanks to streaming platforms, TV commercials can now be targeted as accurately as internet ads.</p>
                    </EuiText>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }} textAlign='right'>
                      <EuiLink
                        onClick={() => {
                          dispatch(initializeCampaignCreate())
                          CreateCampaignStorageManager.DeleteFromLocalStorage()
                          history.push('/build')
                        }}
                        style={{ color: '#f49342' }}>
                        Create Campaign
                      </EuiLink>
                    </EuiText>
                  </React.Fragment>
                </EuiCollapsibleNavGroup>
              )}
              {showCreateBillboardBlurb && (
                <EuiCollapsibleNavGroup background='dark' iconType='fullScreen' title='Digital Billboards' isCollapsible={true} initialIsOpen={true} arrowDisplay='none'>
                  <React.Fragment>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }}>
                      <p>Reinforce your campaigns with roadside billboards. It's the most cost effective brand builder there is.</p>
                    </EuiText>
                    <EuiText size='s' color='ghost' style={{ padding: '0 8px 8px' }} textAlign='right'>
                      <EuiLink
                        onClick={() => {
                          dispatch(initializeCampaignCreate())
                          CreateCampaignStorageManager.DeleteFromLocalStorage()
                          history.push('/build')
                        }}
                        style={{ color: '#f49342' }}>
                        Create Campaign
                      </EuiLink>
                    </EuiText>
                  </React.Fragment>
                </EuiCollapsibleNavGroup>
              )}
              {!showCreateTvBlurb && !showCreateBillboardBlurb && !showCreateInternetBlurb && <EuiHorizontalRule margin='none' />}
              <div className='euiCollapsibleNavGroup__heading'>
                <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='savedObjectsApp' size='l' />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size='xxs'>
                      <EuiLink onClick={() => history.push('/campaigns')} style={{ color: '#1a1c21' }}>
                        <h3 className='euiCollapsibleNavGroup__title' id='campaignsNavItem'>
                          Campaigns
                        </h3>
                      </EuiLink>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
              <EuiHorizontalRule margin='none' />
              {!whiteLabel?.hideReports() && (
                <React.Fragment>
                  <div className='euiCollapsibleNavGroup__heading'>
                    <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                      <EuiFlexItem grow={false}>
                        <EuiIcon type='reportingApp' size='l' />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiTitle size='xxs'>
                          <EuiLink onClick={() => history.push('/reports')} style={{ color: '#1a1c21' }}>
                            <h3 className='euiCollapsibleNavGroup__title' id='reportsNavItem'>
                              Reports
                            </h3>
                          </EuiLink>
                        </EuiTitle>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                  <EuiHorizontalRule margin='none' />
                </React.Fragment>
              )}
              {!whiteLabel?.isVista() && (
                <React.Fragment>
                  <div className='euiCollapsibleNavGroup__heading'>
                    <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                      <EuiFlexItem grow={false}>
                        <EuiIcon type='graphApp' size='l' />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiTitle size='xxs'>
                          <EuiLink onClick={() => history.push('/audiencePixel')} style={{ color: '#1a1c21' }}>
                            <h3 className='euiCollapsibleNavGroup__title' id='audiencePixelNavItem'>
                              Audience Pixel
                            </h3>
                          </EuiLink>
                        </EuiTitle>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                  <EuiHorizontalRule margin='none' />
                </React.Fragment>
              )}
              <div className='euiCollapsibleNavGroup__heading'>
                <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='managementApp' size='l' />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTitle size='xxs'>
                      <EuiLink onClick={() => history.push('/settings')} style={{ color: '#1a1c21' }}>
                        <h3 className='euiCollapsibleNavGroup__title' id='settingsNavItem'>
                          Settings
                        </h3>
                      </EuiLink>
                    </EuiTitle>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
              <EuiHorizontalRule margin='none' />
              {!isVendastaUser && !isShopifyUser && !whiteLabel?.hideKnowledgeBase() && (
                <React.Fragment>
                  <div className='euiCollapsibleNavGroup__heading'>
                    <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
                      <EuiFlexItem grow={false}>
                        <EuiIcon type='notebookApp' size='l' />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiTitle size='xxs'>
                          <EuiLink href={`https://adcritter.com/contact?${whiteLabel?.isAgencies() ? 'questionType=Agencies' : ''}`} target='_blank' style={{ color: '#1a1c21' }} external={false}>
                            <h3 className='euiCollapsibleNavGroup__title' id='knowledgeBaseNavItem'>
                              Contact Support
                            </h3>
                          </EuiLink>
                        </EuiTitle>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                  <EuiHorizontalRule margin='none' />
                </React.Fragment>
              )}
            </EuiCollapsibleNav>
            <EuiHeaderSectionItem>
              <img src={whiteLabel?.getLogo()} alt={whiteLabel?.getName()} style={{ margin: '6px', height: 32, width: 170 }} />
            </EuiHeaderSectionItem>
          </EuiHeaderSection>

          <EuiHeaderSection className={'87537b0e-802e-4b5c-bab0-265f0a68be24'} side={'right'} style={{ marginTop: '4px' }} />

          <EuiHeaderSection side='right'>
            {(isStaffUser || currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9') && (
              <EuiHeaderSectionItem border='none'>
                <EuiHeaderLink onClick={() => history.push('/build/quickCampaign')} style={{ marginRight: 12, color: '#f0b051' }}>
                  Quick Campaign
                </EuiHeaderLink>
              </EuiHeaderSectionItem>
            )}
            {whiteLabel?.isAgencies() && canAccessAgencyPackages.data && (
              <EuiHeaderSectionItem border='none'>
                <EuiHeaderLink onClick={onPackagesClick} style={{ marginRight: 12 }}>
                  Incentive Packages
                </EuiHeaderLink>
              </EuiHeaderSectionItem>
            )}
            {whiteLabel?.isAgencies() && showAccounts && (
              <EuiHeaderSectionItem border='none'>
                <EuiHeaderLink onClick={() => dispatch(showAccountsFlyout())} style={{ marginRight: 12 }}>
                  Clients
                </EuiHeaderLink>
              </EuiHeaderSectionItem>
            )}
            {showAccounts && (
              <EuiHideFor sizes={['xs', 's']}>
                <EuiHeaderSectionItem border='none'>
                  <EuiPopover id='accountSelectorListCloseAcctSwitcher' button={accountSwitcherButton} isOpen={isAccountSwitcherOpen} anchorPosition='downRight' closePopover={closeAccountSwitcher} panelPaddingSize='s'>
                    <React.Fragment>
                      <EuiPopoverTitle hidden={!currentAccount}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFlexGroup justifyContent='spaceBetween'>
                              <EuiFlexItem grow={false}>
                                <span style={{ textTransform: 'none' }}>{accountName}</span>
                              </EuiFlexItem>
                              <EuiFlexItem grow={false}>{currentAccount && <EuiButtonIcon id='refresh' color='primary' size='s' iconType='refresh' onClick={() => getAccounts()} aria-label='refresh' />}</EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPopoverTitle>
                      <EuiSelectable
                        searchProps={{
                          placeholder: 'Find an account'
                        }}
                        hidden={!currentAccount}
                        isLoading={isAccountsLoading}
                        searchable={accounts?.length || 0 > 4 ? true : undefined}
                        options={accountOptions}
                        singleSelection='always'
                        onChange={() => {}}
                        listProps={{ rowHeight: 40, showIcons: false }}>
                        {(list, search) => (
                          <React.Fragment>
                            {search && <EuiPopoverTitle>{search}</EuiPopoverTitle>}
                            {currentAccount && !isAccountsLoading && list}
                            {isAccountsLoading && (
                              <>
                                <EuiSelectableMessage>
                                  <EuiLoadingSpinner size='m' />
                                </EuiSelectableMessage>
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </EuiSelectable>
                      {!isVendastaUser && !isShopifyUser && currentUser.canAccessAllAccounts && (
                        <EuiPopoverFooter>
                          <EuiButton
                            id='newAcct'
                            size='s'
                            fullWidth
                            onClick={() => {
                              history.push('/newAccount')
                              closeAccountSwitcher()
                            }}>
                            Create New Account
                          </EuiButton>
                        </EuiPopoverFooter>
                      )}
                    </React.Fragment>
                  </EuiPopover>
                </EuiHeaderSectionItem>
              </EuiHideFor>
            )}
            {!onlyUsesPlans && !isShopifyUser && (
              <EuiHeaderSectionItem border='none'>
                <EuiPopover id='userProfile' button={userProfileButton} isOpen={isUserProfileOpen} anchorPosition='downRight' closePopover={closeUserProfile} panelPaddingSize='s'>
                  <React.Fragment>
                    <EuiFlexGroup gutterSize='m' responsive={false} className='euiHeaderProfile'>
                      <EuiFlexItem grow={false}>
                        <EuiAvatar name={currentUser!.name} imageUrl={currentUser!.picture} size='xl' />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiText>
                          <p>{currentUser!.name}</p>
                        </EuiText>
                        <EuiText size='s'>
                          <p>{showAccounts ? organizationName : accountName}</p>
                        </EuiText>
                        {isStaffUser && (
                          <>
                            <EuiSpacer size={'xs'} />
                            <EuiSwitch label='Show Internal View' checked={showInternalView} onChange={e => dispatch(setShowInternalView(!showInternalView))} />
                          </>
                        )}
                        <EuiSpacer size='m' />
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFlexGroup justifyContent='spaceBetween'>
                              {showManageSubscription && (
                                <EuiFlexItem grow={false}>
                                  <EuiLink onClick={onAdminClick} color='success'>
                                    Platform Management
                                  </EuiLink>
                                </EuiFlexItem>
                              )}
                              <EuiFlexItem grow={false}>
                                <EuiLink onClick={onLogoutClick} color='success'>
                                  Log out
                                </EuiLink>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </React.Fragment>
                </EuiPopover>
              </EuiHeaderSectionItem>
            )}
          </EuiHeaderSection>
        </EuiHeader>
        {showAccounts && (
          <EuiShowFor sizes={['xs', 's']}>
            <EuiHeader position='fixed'>
              <EuiHeaderSection side='right'>
                <EuiHeaderSectionItem border='none'>
                  <EuiPopover id='accountSelectorListSmallAcct' button={smallAccountSwitcherButton} isOpen={isSmallAccountSwitcherOpen} anchorPosition='downRight' closePopover={closeSmallAccountSwitcher} panelPaddingSize='s'>
                    <EuiPopoverTitle hidden={!currentAccount}>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                              <span style={{ textTransform: 'none' }}>{accountName}</span>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>{currentAccount && <EuiButtonIcon id='refresh' color='primary' size='s' iconType='refresh' onClick={() => getAccounts()} aria-label='refresh' />}</EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPopoverTitle>
                    <EuiSelectable
                      searchProps={{
                        placeholder: 'Find an account'
                      }}
                      hidden={!currentAccount}
                      isLoading={isAccountsLoading}
                      searchable={accounts?.length || 0 > 4 ? true : undefined}
                      options={accountOptions}
                      singleSelection='always'
                      onChange={() => {}}
                      listProps={{ rowHeight: 40, showIcons: false }}>
                      {(list, search) => (
                        <React.Fragment>
                          {search && <EuiPopoverTitle>{search}</EuiPopoverTitle>}
                          {currentAccount && !isAccountsLoading && list}
                          {isAccountsLoading && (
                            <React.Fragment>
                              <EuiSelectableMessage>
                                <EuiLoadingSpinner size='m' />
                              </EuiSelectableMessage>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </EuiSelectable>
                    {!isVendastaUser && !isShopifyUser && currentUser.canAccessAllAccounts && (
                      <EuiPopoverFooter>
                        <EuiButton
                          id='newAcct'
                          size='s'
                          fullWidth
                          onClick={() => {
                            history.push('/newAccount')
                            closeAccountSwitcher()
                          }}>
                          Create new account
                        </EuiButton>
                      </EuiPopoverFooter>
                    )}
                  </EuiPopover>
                </EuiHeaderSectionItem>
              </EuiHeaderSection>
            </EuiHeader>
          </EuiShowFor>
        )}
        <AccountsFlyout />
        <SelfServicePackagesFlyoutPage />
      </React.Fragment>
    )
  )
}
