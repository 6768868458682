import { Invoice } from './Invoice'

export interface Organization {
  id: string
  name: string
  billingEmail: string
  phoneNumber: string
  targetingTemplates: string[]
  hasPaymentMethod: boolean
  hasCanceledSubscription: boolean
  hasUnpaidSubscription: boolean
  includedCampaignIds: string[] | null
  group: string | null
  signupMethod: string
  campaignAdvisorId: string
  isAgency: boolean
  hasBundle: boolean
  subscriptionId: string
  allowBankAccountPayments: boolean
  permissions?: Permission[]
  defaultTargetingId?: string
}

export interface Permission {
  permissionType: PermissionType
}

export enum PermissionType {
  UploadAudience = 'UploadAudience',
  UseTemplates = 'UseTemplates'
}

export interface PaymentMethod {
  id: string
  name: string
  brand: string
  last4: string
  expiryMonth: number
  expiryYear: number
  isDefault: boolean
  spendCap?: number
  customerId: string
  paymentMethodType: PaymentMethodType
}

export enum PaymentMethodType {
  Card = 'Card',
  BankAccount = 'BankAccount',
  Token = 'Token'
}

export const displayPaymentMethod = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod) {
    return 'N/A'
  }

  return `${paymentMethod.brand} card ending ${displayPaymentMethodShort(paymentMethod)}`
}

export const displayPaymentMethodShort = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod) {
    return 'N/A'
  }

  return `xxxx${paymentMethod.last4}, expiring ${paymentMethod.expiryMonth.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}/${paymentMethod.expiryYear}`
}

export interface PaymentMethodSetup {
  clientSecret: string
}

export interface PaymentMethodPayment {
  clientSecret: string
  paymentIntentId: string
}

export interface Subscription {
  level: string
  status: string
  nextBillDate: string
  nextBillAmount: number
  canceledDate: string
  cancelAtPeriodEnd: boolean
  invoices: Invoice[]
}

export interface OrganizationPrepareRestart {
  paymentIntentId: string
  clientSecret: string
  paymentMethodId: string | null
  amount: number
  hasValidSubscription: boolean
}

export interface OrganizationPrepareUnpaid {
  isStillUnpaid: boolean
  clientSecret: string
  invoiceId: string | null
  paymentMethodId: string
  amount: number
  paymentMethods: PaymentMethod[]
  error: string
  setupIntentId: string
}

export interface OrganizationPrepareCapture {
  clientSecret: string
  setupIntentId: string
  paymentMethodId: string
  redirectUrl: string
  amount: number
  error: string
  remainingBalance?: number
  cappedAmount?: number
  needsConfirmation: boolean
}

export interface OrganizationPrepareCaptureResult {
  success: boolean
  error: string
}
