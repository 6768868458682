import React from 'react'

import { EuiFormRow, EuiSpacer } from '@elastic/eui'

import { AudienceTargeting } from '../../../api/interfaces/TargetingTemplate'
import { AgeRangeTargeter, GenderTargeter, HouseholdIncomeTargeter } from '../../targeters'

type AudienceTargetingFormProps = {
  audienceTargeting: AudienceTargeting
  onAudienceTargetingChanged: (audienceTargeting: AudienceTargeting) => void
}

export const AudienceTargetingForm = ({ audienceTargeting, onAudienceTargetingChanged }: AudienceTargetingFormProps) => {
  !audienceTargeting.ageRanges && (audienceTargeting.ageRanges = ['all'])
  !audienceTargeting.genders && (audienceTargeting.genders = ['all'])
  !audienceTargeting.householdIncomes && (audienceTargeting.householdIncomes = ['all'])

  return (
    <>
      <EuiFormRow label='Age Ranges' fullWidth>
        <AgeRangeTargeter
          ageRanges={audienceTargeting.ageRanges}
          setAgeRanges={ageRanges =>
            onAudienceTargetingChanged({
              ...audienceTargeting,
              ageRanges: ageRanges as AudienceTargeting['ageRanges']
            })
          }
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Gender' fullWidth>
        <GenderTargeter
          genders={audienceTargeting.genders}
          setGenders={genders =>
            onAudienceTargetingChanged({
              ...audienceTargeting,
              genders: genders as AudienceTargeting['genders']
            })
          }
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Household Incomes' fullWidth>
        <HouseholdIncomeTargeter
          householdIncomes={audienceTargeting.householdIncomes}
          setHouseholdIncomes={householdIncomes =>
            onAudienceTargetingChanged({
              ...audienceTargeting,
              householdIncomes: householdIncomes as AudienceTargeting['householdIncomes']
            })
          }
        />
      </EuiFormRow>
    </>
  )
}
