import { Global } from '@emotion/react'
import { useLoadScript } from '@react-google-maps/api'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { clarity } from 'react-microsoft-clarity'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'

import { EuiProvider, EuiCallOut, EuiGlobalToastList } from '@elastic/eui'

import { apiGetOrganizationSubscriptions } from 'api/adcritterApi'
import { Subscription } from 'api/interfaces'
import { HeaderBar } from 'components/HeaderBar'
import { AuthenticatorRoutes } from 'components/authentication/useAuthenticationSwitch'
import { useThirdPartyAuthentication } from 'components/authentication/useThirdPartyAuthentication'
import AdministrationFlyout from 'features/organization/AdministrationFlyout'
import { useReturnUrl } from 'features/routing/useReturnUrl'
import { useVendastaCheckIsAuthenticated } from 'features/vendasta/login/useVendastaCheckIsAuthenticated'
import { useVendastaRenderNavbar } from 'features/vendasta/login/useVendastaRenderNavbar'
import history from 'services/HistoryService'
import useListenScreenSize from 'utils/useListenScreenSize'
import { WhiteLabelProvider } from 'whiteLabel/WhiteLabelContext'

import { OrganizationPermissionsProvider } from '../providers/OrganizationPermissionsProvider'
import Routes from './Routes'
import { removeToast, setGoogleLoaded } from './appSlice'
import config from './config'
import { fetchDictionary, fetchOpenDictionary } from './dictionarySlice'
import { RootState } from './rootReducer'
import { useMicrosoftAdvertisingScript } from './useMicrosoftAdvertisingScript'

// @ts-ignore
const libraries: any = ['drawing', 'geometry', 'places']

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, currentUser, currentOrganization, toasts, isLoggedIn, isInSignupProcess } = useSelector((state: RootState) => state.app)
  const { url } = useRouteMatch()
  const location = useLocation()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [adBlockerDetected, setAdBlockerDetected] = useState(false)
  const [organizationSubscription, setOrganizationSubscription] = useState<Subscription>()
  const browserHistory = useHistory()
  const isVendastaAuthenticatedDone = useVendastaCheckIsAuthenticated()
  useListenScreenSize()

  useVendastaRenderNavbar(isVendastaAuthenticatedDone)

  useMicrosoftAdvertisingScript()
  useThirdPartyAuthentication()
  useReturnUrl()

  useEffect(() => {
    if (isInSignupProcess && !url.startsWith('/build/internet/vista/')) {
      history.push('/build/internet/vista/start')
    }
  }, [isInSignupProcess, url])

  useEffect(() => {
    if (browserHistory) {
      browserHistory.listen(() => {
        ReactPixel.pageView()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoggedIn && !!currentAccount?.id && !!currentUser && !currentUser.roles.includes('staff') && !document.getElementById('pxl-img') && currentAccount.pxlUid) {
      let pimg = document.createElement('img')
      pimg.width = 1
      pimg.height = 1
      pimg.id = 'pxl-img'
      pimg.src = `https://ib.adnxs.com/pixie?pi=${currentAccount.pxlUid}&e=PageView&script=0`
      pimg.referrerPolicy = 'strict-origin-when-cross-origin'
      document.body.appendChild(pimg)
    }
  }, [isLoggedIn, currentAccount?.id, currentUser])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMaps.APIKEY,
    libraries: libraries
  })

  useEffect(() => {
    if (isLoaded) {
      dispatch(setGoogleLoaded())
    }
  }, [isLoaded, dispatch, currentUser])

  useEffect(() => {
    if (config.clarity.PROJECT) {
      clarity.init(config.clarity.PROJECT)
    }
  }, [config.clarity.PROJECT])

  useEffect(() => {
    if (currentOrganization) {
      apiGetOrganizationSubscriptions().then(result => {
        setOrganizationSubscription(result.data)
      })
    }
  }, [currentOrganization])

  useEffect(() => {
    if (currentOrganization && currentUser && config.gtag.ANALYTICSID) {
      ReactGA.set({ user_id: currentOrganization.id, email: currentUser.email, phone_number: currentOrganization.phoneNumber })
    }
    if (currentOrganization && currentUser && config.facebook.PIXEL_ID) {
      ReactPixel.init(config.facebook.PIXEL_ID, { em: currentUser.email, ph: currentOrganization.phoneNumber, external_id: currentOrganization.id } as any)
    }
  }, [currentOrganization, currentUser])

  useEffect(() => {
    if (currentOrganization && currentOrganization.hasCanceledSubscription && currentUser && !currentUser.roles.includes('admin') && !currentUser.roles.includes('staff')) {
      history.push('/restart')
    }
  }, [currentOrganization, currentUser])

  useEffect(() => {
    if (isLoggedIn) {
      try {
        fetch(
          new Request('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
            method: 'HEAD',
            mode: 'no-cors'
          })
        )
          .then(() => setAdBlockerDetected(false))
          .catch(() => setAdBlockerDetected(true))
      } catch (error) {
        setAdBlockerDetected(true)
      }
    } else {
      setAdBlockerDetected(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!dictionary.isOpenDictionaryLoaded) dispatch(fetchOpenDictionary())
  }, [dispatch, dictionary.isOpenDictionaryLoaded])

  useEffect(() => {
    if (!dictionary.isDictionaryLoaded && isLoggedIn) dispatch(fetchDictionary())
  }, [dispatch, dictionary.isDictionaryLoaded, isLoggedIn])

  function showHeaderBasedOnLocation() {
    if (location.pathname.startsWith('/login') || location.pathname.startsWith('/token_login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/restart')) {
      return false
    }

    if (location.pathname.startsWith(`/${AuthenticatorRoutes.Vendasta}`)) {
      return false
    }

    if (location.pathname.startsWith('/shopify')) {
      return false
    }

    if (location.pathname.startsWith('/invoices')) {
      return false
    }

    if (location.pathname.startsWith('/adViewer')) {
      return false
    }

    return !isInSignupProcess
  }

  return (
    <EuiProvider colorMode='light'>
      <Global
        styles={{
          '*:focus:focus-visible': {
            outlineStyle: 'none'
          }
        }}
      />
      <OrganizationPermissionsProvider>
        <WhiteLabelProvider>
          {isLoggedIn && showHeaderBasedOnLocation() && <HeaderBar />}
          {adBlockerDetected && (
            <div style={{ padding: 15, backgroundColor: '#D3DAE6' }}>
              <EuiCallOut color='danger' title='Please turn off all ad blockers for this site to function correctly' iconType='alert' size='s' />
            </div>
          )}
          {organizationSubscription?.status === 'unpaid' && (
            <div style={{ padding: 15, backgroundColor: '#D3DAE6' }}>
              <EuiCallOut color='danger' title='Your subscription is currently unpaid. Please update your default payment method.' iconType='alert' size='s' />
            </div>
          )}
          <Routes />
          <AdministrationFlyout />
          <EuiGlobalToastList toasts={toasts} dismissToast={t => dispatch(removeToast(t))} toastLifeTimeMs={6000} />
        </WhiteLabelProvider>
      </OrganizationPermissionsProvider>
    </EuiProvider>
  )
}

export default App
