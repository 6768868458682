import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiRadioGroup, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { LocationTargetingType, TargetingType } from '../../../../api/interfaces/CampaignTargeting'
import { AgeRange, AudienceTargeting, BaseTargeting, FrequencyCapUnit, Gender, HouseholdIncome, InternetTargeting, LocationTargeting, TvTargeting } from '../../../../api/interfaces/TargetingTemplate'
import { RootState } from '../../../../app/rootReducer'
import { BackNextFooter } from '../../../../components/navigation/BackNextFooter'
import { DefaultDayParts } from '../../../../components/targeters/DayPartPicker'
import { AudienceTargetingForm } from '../../../../components/targeting/TargetingForm/AudienceTargetingForm'
import { BillboardTargetingForm } from '../../../../components/targeting/TargetingForm/BillboardTargetingForm'
import { InternetTargetingForm } from '../../../../components/targeting/TargetingForm/InternetTargetingForm'
import { LocationTargetingForm } from '../../../../components/targeting/TargetingForm/LocationTargetingForm'
import { TvTargetingForm } from '../../../../components/targeting/TargetingForm/TvTargetingForm'
import { getAudienceTargetingFromTargetingTemplate, getCampaignDeliveryFromTargetingTemplate, getLocationTargetingFromTargetingTemplate } from '../../../../utils/TransformTargeting'
import { DefaultTargetingState } from '../../DefaultTargetingSlice'
import { ICampaignBuilderWorkflowStep } from '../creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from '../useCampaignBuilderActions'

interface ImportTargetingPageProps extends ICampaignBuilderWorkflowStep {}

export const ImportTargetingPage = (props: ImportTargetingPageProps) => {
  const campaignWorkflow = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const campaignTargeting = campaignWorkflow.campaignTargeting
  const [targetingFormValues, setTargetingFormValues] = useState<DefaultTargetingState>({
    locationTargeting: {
      locationType: campaignTargeting.locationTargeting.targetingType,
      regions: campaignTargeting.locationTargeting.regions,
      dmas: campaignTargeting.locationTargeting.dmas,
      cities: campaignTargeting.locationTargeting.cities,
      postalCodes: campaignTargeting.locationTargeting.zips,
      addresses: campaignTargeting.locationTargeting.addresses ?? [],
      geoFences: campaignTargeting.locationTargeting.geoFences ?? [],
      geoCircles: campaignTargeting.locationTargeting.geoCircles ?? [],
      geoPolygons: campaignTargeting.locationTargeting.geoPolygons ?? [],
      geoRectangles: campaignTargeting.locationTargeting.geoRectangles ?? []
    },
    audienceTargeting: {
      ageRanges: (campaignTargeting.audienceTargeting.demographics.ageRanges as AgeRange[]) ?? ['all' as AgeRange],
      genders: (campaignTargeting.audienceTargeting.demographics.genders as Gender[]) ?? ['all' as Gender],
      householdIncomes: (campaignTargeting.audienceTargeting.demographics.householdIncomes as HouseholdIncome[]) ?? ['all' as HouseholdIncome]
    },
    tvTargeting: {
      segments: campaignTargeting.audienceTargeting.segments ?? [],
      dayParts: campaignTargeting.campaignDelivery.dayParts ?? DefaultDayParts,
      frequencyCapSettings: {
        frequency: campaignTargeting.campaignDelivery.frequency,
        unit: campaignTargeting.campaignDelivery.frequencyUnit === 0 ? FrequencyCapUnit.Hourly : campaignTargeting.campaignDelivery.frequencyUnit === 1 ? FrequencyCapUnit.Daily : FrequencyCapUnit.Weekly
      },
      cpm: campaignTargeting.campaignDelivery.targetCPM
    },
    internetTargeting: {
      segments: campaignTargeting.audienceTargeting.segments ?? [],
      dayParts: campaignTargeting.campaignDelivery.dayParts ?? DefaultDayParts,
      frequencyCapSettings: {
        frequency: campaignTargeting.campaignDelivery.frequency,
        unit: campaignTargeting.campaignDelivery.frequencyUnit === 0 ? FrequencyCapUnit.Hourly : campaignTargeting.campaignDelivery.frequencyUnit === 1 ? FrequencyCapUnit.Daily : FrequencyCapUnit.Weekly
      },
      advancedInternetTargeting: {
        deviceSettings: {
          targetPc: campaignTargeting.campaignDelivery.deviceSettings.targetPc,
          targetPhone: campaignTargeting.campaignDelivery.deviceSettings.targetPhone,
          targetTablet: campaignTargeting.campaignDelivery.deviceSettings.targetTablet
        },
        inventorySettings: {
          targetApp: campaignTargeting.campaignDelivery.inventorySettings.targetApp,
          targetWeb: campaignTargeting.campaignDelivery.inventorySettings.targetWeb
        }
      }
    },
    billboardTargeting: {
      dayParts: campaignTargeting.campaignDelivery.dayParts ?? DefaultDayParts
    },
    targetingPriority: campaignTargeting.targetingType
  })

  const { targetImportTemplateComplete } = useCampaignBuilderActions()

  const onLocationTargetingChange = (locationTargeting: LocationTargeting) => {
    setTargetingFormValues({ ...targetingFormValues, locationTargeting })
  }

  const onAudienceTargetingChanged = (audienceTargeting: AudienceTargeting) => {
    setTargetingFormValues({ ...targetingFormValues, audienceTargeting })
  }

  const onInternetTargetingChanged = (internetTargeting: InternetTargeting) => {
    setTargetingFormValues({ ...targetingFormValues, internetTargeting })
  }

  const onTvTargetingChanged = (tvTargeting: TvTargeting) => {
    setTargetingFormValues({ ...targetingFormValues, tvTargeting })
  }

  const onBillboardTargetingChanged = (billboardTargeting: BaseTargeting) => {
    setTargetingFormValues({ ...targetingFormValues, billboardTargeting })
  }

  const onContinueClick = () => {
    const audienceTargeting = getAudienceTargetingFromTargetingTemplate(targetingFormValues, campaignWorkflow.campaignType)

    const locationTargeting = getLocationTargetingFromTargetingTemplate(targetingFormValues)

    const campaignDelivery = getCampaignDeliveryFromTargetingTemplate(targetingFormValues, campaignWorkflow.campaignType)

    targetImportTemplateComplete(locationTargeting, audienceTargeting, campaignDelivery, targetingFormValues.targetingPriority)
  }

  const onTargetingPrioritizationChange = (targetingType: TargetingType) => {
    setTargetingFormValues({
      ...targetingFormValues,
      targetingPriority: targetingType
    })
  }

  const radios = [
    {
      id: TargetingType.Location,
      label: 'Location'
    },
    {
      id: TargetingType.Audience,
      label: 'Audience'
    }
  ]

  const isValid = () => {
    if (targetingFormValues.targetingPriority === 'Audience') {
      if (campaignWorkflow.campaignType === 'Native' || campaignWorkflow.campaignType === 'Display') {
        return targetingFormValues.internetTargeting.segments && targetingFormValues.internetTargeting.segments.length > 0
      } else if (campaignWorkflow.campaignType === 'TV') {
        return targetingFormValues.tvTargeting.segments && targetingFormValues.tvTargeting.segments.length > 0
      }
      return false
    } else {
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.Address && targetingFormValues.locationTargeting.addresses.length === 0) {
        return false
      }
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.City && targetingFormValues.locationTargeting.cities.length === 0) {
        return false
      }
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.DMA && targetingFormValues.locationTargeting.dmas.length === 0) {
        return false
      }
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.Region && targetingFormValues.locationTargeting.regions.length === 0) {
        return false
      }
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.Zip && targetingFormValues.locationTargeting.postalCodes.length === 0) {
        return false
      }
      if (targetingFormValues.locationTargeting.locationType === LocationTargetingType.Map) {
        return true
      }
      return true
    }
  }

  console.log(targetingFormValues)

  return (
    <>
      <Helmet>
        <title>Import Targeting Template</title>
      </Helmet>
      <EuiFlexGroup direction={'column'}>
        <EuiFlexItem>
          <EuiForm>
            <EuiTitle size={'s'}>
              <h2>Targeting Prioritization</h2>
            </EuiTitle>
            <EuiRadioGroup options={radios} idSelected={targetingFormValues.targetingPriority} onChange={id => onTargetingPrioritizationChange(id as TargetingType)} name='targeting prioritization radio group' />
            <EuiSpacer />
            <EuiTitle size={'s'}>
              <h2>Location Targeting</h2>
            </EuiTitle>
            <LocationTargetingForm locationTargeting={targetingFormValues.locationTargeting} onLocationTargetingChange={onLocationTargetingChange} targetingType={targetingFormValues.targetingPriority} />
            <EuiSpacer />
            <EuiTitle size={'s'}>
              <h2>Demographic Targeting</h2>
            </EuiTitle>
            <AudienceTargetingForm audienceTargeting={targetingFormValues.audienceTargeting} onAudienceTargetingChanged={onAudienceTargetingChanged} />
            <EuiSpacer />
            {(campaignWorkflow.campaignType === 'Native' || campaignWorkflow.campaignType === 'Display') && <InternetTargetingForm internetTargeting={targetingFormValues.internetTargeting} onInternetTargetingChanged={onInternetTargetingChanged} />}
            {campaignWorkflow.campaignType === 'TV' && (
              <EuiFormRow label={'TV Targeting'} fullWidth>
                <TvTargetingForm tvTargeting={targetingFormValues.tvTargeting} onTvTargetingChanged={onTvTargetingChanged} />
              </EuiFormRow>
            )}
            {campaignWorkflow.campaignType === 'Billboard' && (
              <>
                <EuiTitle size={'s'}>
                  <h2>Billboard Targeting</h2>
                </EuiTitle>
                <EuiFormRow label={'Billboard Targeting'} fullWidth>
                  <BillboardTargetingForm billboardTargeting={targetingFormValues.billboardTargeting} onBillboardTargetingChanged={onBillboardTargetingChanged} />
                </EuiFormRow>
              </>
            )}
          </EuiForm>
        </EuiFlexItem>
        {!isValid() && (
          <EuiFlexItem>
            <EuiText color='warning' size={'xs'}>
              {targetingFormValues.targetingPriority === 'Audience' && 'Please define at least one audience.'}
              {targetingFormValues.targetingPriority === 'Location' && 'Please select at least one location.'}
            </EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true}>
          <BackNextFooter onBack={props.onBack} onNext={onContinueClick} isNextDisabled={!isValid()} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
