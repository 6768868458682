import React from 'react';



import { EuiBadge, EuiBasicTableColumn, EuiInMemoryTable, EuiLink, EuiText, SortDirection } from '@elastic/eui';



import { TargetingTemplate } from 'api/interfaces/TargetingTemplate';
import history from 'services/HistoryService'



import { Account } from '../../api/interfaces';


type TargetingTableProps = {
  targeting?: TargetingTemplate[]
  accounts?: Account[]
  onEditClick?: (targeting: TargetingTemplate) => void
  onDeleteClicked?: (targeting: TargetingTemplate) => void
  loading?: boolean
  organizationDefaultTargetingId?: string
}
export const TargetingTable = ({ targeting, onEditClick, onDeleteClicked, loading, organizationDefaultTargetingId, accounts }: TargetingTableProps) => {
  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: 'orgId',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 20, 25, 0]
  }

  const search = {
    box: {
      incremental: true
    }
  }

  const columns: Array<EuiBasicTableColumn<TargetingTemplate>> = [
    {
      field: 'name',
      name: 'Name',
      render: (name: TargetingTemplate['name']) => <strong>{name}</strong>,
      sortable: (item: TargetingTemplate) => item.name.toLowerCase()
    },
    {
      render: (targeting: TargetingTemplate) => {
        return targeting.id === organizationDefaultTargetingId && <EuiBadge color='success'>Organization Default</EuiBadge>
      }
    },
    {
      field: 'locationTargeting.locationType',
      name: 'Location Targeting',
      render: (locationType, _record) => locationType,
      sortable: (targeting: TargetingTemplate) => targeting.locationTargeting?.locationType?.toLowerCase()
    },
    {
      name: 'Accounts Using Targeting',
      render: (targeting: TargetingTemplate) => {
        const accountsUsingTargeting = accounts?.filter(account => account.defaultTargetingId === targeting.id)

        return accountsUsingTargeting?.map((account, index) => {
          return (
            <>
              <EuiText key={index}>
                <EuiLink key={`${index}-link`} target={'_blank'} onClick={() => history.push(`/customers/accounts/${account.id}`)}>
                  {account.name}
                </EuiLink>
                {index !== accountsUsingTargeting.length - 1 && ' , '}
              </EuiText>
            </>
          )
        })
      }
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          description: 'Edit this targeting item',
          icon: 'pencil',
          type: 'icon',
          onClick: item => {
            onEditClick && onEditClick(item)
          },
          available: _item => !loading
        },
        {
          name: 'Delete',
          description: 'Delete this targeting item',
          icon: 'trash',
          type: 'icon',
          color: 'danger',
          onClick: item => {
            onDeleteClicked && onDeleteClicked(item)
          },
          available: _item => !loading
        }
      ]
    }
  ]

  return <EuiInMemoryTable search={search} pagination={pagination} sorting={sorting} loading={loading} tableCaption='Demo of EuiBasicTable' items={targeting ?? []} rowHeader='name' columns={columns} />
}
